<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :task="task"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'constructionRelactionTask',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'workPermit',
      tabItems: [
        { name: 'workPermit', icon: 'info', label: '작업허가서', component: () => import(`${'./constructionRelactionTaskWorkPermit.vue'}`) },
        { name: 'riskAssess', icon: 'info', label: '위험성평가', component: () => import(`${'./constructionRelactionTaskRiskAssess.vue'}`) },
        { name: 'safetyCheck', icon: 'info', label: '안전점검', component: () => import(`${'./constructionRelactionTaskSafetyCheck.vue'}`) },
        { name: 'tbm', icon: 'info', label: 'TBM', component: () => import(`${'./constructionRelactionTaskTbm.vue'}`) },
        { name: 'impr', icon: 'info', label: '부적합/시정조치', component: () => import(`${'./constructionRelactionTaskImpr.vue'}`) },
      ],
      task: {
        insertUrl: '',
        deleteUrl: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 150) + 'px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.task.insertUrl = transactionConfig.sop.scm.construction.task.insert.url;
      this.task.deleteUrl = transactionConfig.sop.scm.construction.task.delete.url;
      // code setting
      // list setting
    },
  }
};
</script>